import classNames from 'classnames';
import { ReactNode } from 'react';
import IconLoading from '~/components/icons/streamline/line/IconLoading';

export default function AutoComplete(props: {
  children: JSX.Element;
  highlighted?: boolean;
  icon: ReactNode;
  disabled?: boolean;
  loading?: string;
  onClick: () => void;
  onMouseEnter?: () => void;
}) {
  return (
    <div
      onClick={props.onClick}
      onMouseEnter={props.onMouseEnter}
      className={classNames(
        'p-3 text-sm content bg-content text-read rounded-none first:rounded-t-lg last:rounded-b-lg',
        {
          '!bg-theme-primary !text-white !border-white group-highlighted shadow-lg':
            props.highlighted && !props.loading,
          'pointer-events-none': !!props.loading || props.disabled,
          'hover:bg-gray-50 hover:border-white': !props.highlighted,
          'shadow-sm cursor-pointer': !props.disabled,
          'opacity-10': props.disabled,
        },
      )}
    >
      <div className="flex items-center space-x-4">
        <div className="size-4">{props.loading ? <IconLoading /> : props.icon}</div>
        {props.loading ? <div>{props.loading}</div> : props.children}
      </div>
    </div>
  );
}
